import React, { useState } from "react";
import { Grid, Typography, Button, Container, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TextField from "@mui/material/TextField";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import map from "../images/map.jpg";

const useStyles = makeStyles((theme) => ({
  map: {
    backgroundImage: `url(${map})`,
  },
  contact: {
    flexGrow: 1,
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  card: {
    borderRadius: 20,
    padding: "30px",
    height: "100%",
  },
  head: {
    color: "#fff",
    fontSize: "2.6em",
    fontWeight: 500,
  },
  content: {
    color: "#fff",
    fontSize: "1.4em",
    padding: "5px",
  },
  bottom: {
    background: "#06379c",
    borderRadius: 20,
  },
  button: {
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: 600,
    border: "2px solid #fff",
    color: "#000",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    background: "#06379c",
    "&:hover": {
      backgroundColor: "#0a067a",
      boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
      color: "#fff",
      transform: "translateY(-3px)",
    },
  },
  submit: {
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: 600,
    color: "#000",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    background: "#06379c",
    "&:hover": {
      backgroundColor: "#0a067a",
      boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
      color: "#fff",
      transform: "translateY(-3px)",
    },
  },
  center: {
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: "25px",
  },
  touch: {
    color: "#0000c1",
    fontWeight: 600,
    fontSize: "2.4em",
    textAlign: "center",
    marginBottom: "10px",
  },
  textField: {
    padding: "10px 20px",
  },
}));

const Contact: React.FC = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [num, setNum] = useState<string>("");
  const [msg, setMsg] = useState<string>("");

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleNum = (event) => {
    setNum(event.target.value);
  };
  const handleMsg = (event) => {
    setMsg(event.target.value);
  };
  return (
    <div className={classes.map}>
      <Container>
        <Grid className={classes.contact} spacing={4} container>
          <Grid item sm={12} md={7} margin="auto">
            <Paper className={classes.card} style={{ background: "#0000c1" }} >
              <div className={classes.center} style={{ marginTop: "5%" }}>
                <LocationCityIcon
                  style={{ fontSize: "60px", color: "#FFFF" }}
                />
              </div>
              <Typography
                className={classes.content}
                variant="h5"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                AiM4u Software Solutions Private Limited, 8th Floor, SP-TBI,Munshi Nagar, Andheri (W) Mumbai,Maharashtra 400058
              </Typography>
              <div
                className={classes.center}
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Button
                  variant="contained"
                  style={{ background: "#0000c1" }}
                  className={classes.button}
                  startIcon={
                    <LocationOnIcon
                      style={{ fontSize: "25px", color: "#FFFF" }}
                    />
                    
                  }
                  href="https://goo.gl/maps/KTv6BYf7y8xzA5BT8"
                >
                  <Typography style={{ color: "#FFFF" }}>
                    Google Maps
                  </Typography>
                </Button>
              </div>
            </Paper>
          </Grid>
          {/* <Grid item sm={12} md={7}>
            <Paper className={classes.card} style={{ background: "#FFF" }}>
              <Typography className={classes.touch}>Get In Touch</Typography>
              <Grid container>
                <Grid item className={classes.textField} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    value={firstName}
                    name="fname"
                    onChange={handleFirstName}
                  />
                </Grid>
                <Grid item className={classes.textField} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    value={lastName}
                    name="lname"
                    onChange={handleLastName}
                  />
                </Grid>
                <Grid item className={classes.textField} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    value={email}
                    name="email"
                    onChange={handleEmail}
                  />
                </Grid>
                <Grid item className={classes.textField} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Phone"
                    value={num}
                    name="phone"
                    onChange={handleNum}
                  />
                </Grid>
                <Grid item className={classes.textField} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Your Message here.."
                    multiline
                    value={msg}
                    name="msg"
                    onChange={handleMsg}
                  />
                </Grid>
                <Grid item className={classes.textField} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ background: "#0000c1" }}
                    className={classes.submit}
                  >
                    <Typography style={{ textAlign: "center", color: "#fff" }}>
                      Submit
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              
            </Paper>
          </Grid> */}
          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <Paper className={classes.bottom}>
              <Grid container className={classes.center}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingTop: "20px", paddingLeft: "20px" }}
                >
                  <Typography style={{ textAlign: "center", color: "#fff" }}>
                    Contact Us Now
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingTop: "10px", paddingLeft: "20px" }}
                >
                  <Button
                    variant="contained"
                    style={{ background: "#1E1E30" }}
                    className={classes.button}
                    startIcon={
                      <PhoneIcon style={{ fontSize: "20px", color: "#fff" }} />
                    }
                    href="tel:+918657424574"
                  >
                    <Typography style={{ textAlign: "center", color: "#fff" }}>
                      {" "}
                      +91 8657424574
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "20px",
                    paddingBottom: "15px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ background: "#1E1E30" }}
                    className={classes.button}
                    startIcon={
                      <EmailIcon style={{ fontSize: "20px", color: "#fff" }} />
                    }
                    href="mailto:support@aim4u.co.in"
                  >
                    <Typography style={{ color: "#fff" }}>
                    support@aim4u.co.in
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Contact;
