import React, { useState } from "react";
import Slide from "./Slide";
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SpringConfig } from "react-spring";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  NavigationButtons: {
    position: "relative",
    display: "flex",
    height: "60px",
    margin: "0 auto",
    justifyContent: "space-between",
    zIndex: 1000,
  },
  navIcon: { color: "#3F67F6", fontSize: "50px" },
}));

function mod(a, b) {
  return ((a % b) + b) % b;
}

interface Props {
  slides: {
    [key: string]: string;
  }[];
  offsetRadius: number;
  showNavigation: boolean;
  animationConfig: SpringConfig;
}

const VerticalCarousel = ({
  slides,
  offsetRadius,
  showNavigation,
  animationConfig,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<{
    index: number;
    goToSlide: any;
    prevPropsGoToSlide: number;
    newSlide: boolean;
  }>({
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  });

  const modBySlidesLength = (index: number) => {
    return mod(index, slides.length);
  };

  const moveSlide = (direction: number) => {
    setState({
      ...state,
      index: modBySlidesLength(state.index + direction),
      goToSlide: null,
    });
  };

  const clampOffsetRadius = (offsetRadius: number) => {
    const upperBound = Math.floor((slides.length - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }
    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  };

  const getPresentableSlides = () => {
    const { index } = state;
    let offsetRad: number = clampOffsetRadius(offsetRadius);
    let presentableSlides = [];

    for (let i = -offsetRad; i < 1 + offsetRad; i++) {
      presentableSlides.push(slides[modBySlidesLength(index + i)]);
    }

    return presentableSlides;
  };

  return (
    <React.Fragment>
      {showNavigation && (
        <div className={classes.NavigationButtons}>
          <IconButton onClick={() => moveSlide(1)} aria-label="up" size="large">
            <ArrowDropUpIcon className={classes.navIcon} />
          </IconButton>
        </div>
      )}
      <div className={classes.Wrapper}>
        {getPresentableSlides().map((slide, presentableIndex) => (
          <Slide
            key={slide.key}
            content={slide}
            moveSlide={moveSlide}
            offsetRadius={clampOffsetRadius(offsetRadius)}
            index={presentableIndex}
            animationConfig={animationConfig}
          />
        ))}
      </div>
      {showNavigation && (
        <div className={classes.NavigationButtons}>
          <IconButton onClick={() => moveSlide(1)} aria-label="down" size="large">
            <ArrowDropDownIcon className={classes.navIcon} />
          </IconButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default VerticalCarousel;
