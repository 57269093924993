import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    background: {
      background: "#3F67F6",
      textAlign: "center",
      height: "55vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        height: "65vh",
        padding: "40px 30px"
      },
    },
    heading: {
        color: "#FFF",
        fontSize: "45px",
        fontWeight: "600",
        margin: "25px auto",
        [theme.breakpoints.down("md")]: {
            fontSize: "35px",
          },
      },
      info: {
        color: "#FFF",
        fontSize: "21px",
        fontWeight: "400",
        width: "70%",
        [theme.breakpoints.down("md")]: {
            fontSize: "18px",
            width: "100%",
        },
      },

}));

const BlogInfoSection: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.background}>
                <h2 className={classes.heading}> Welcome to Our Blog </h2>
                <p className={classes.info}> A place of discovery and learning where you can stay updated with skin care routines, healthy skin, growing awareness of skin diseases by AiM4u Team. </p>
            </div>
        </>
    )
};

export default BlogInfoSection;