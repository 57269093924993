import React from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Navbar from "./components/sections/Navbar";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Careers from "./components/pages/Careers";
import ContactPage from "./components/pages/ContactPage";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material";
import FAQs from "./components/pages/FAQs";
import Policy from "./components/pages/Policy";

import { Nav } from "react-bootstrap";
import SkinzyTernLanding from "./components/pages/CallForInternshipPage";
import RegistrationForm from "./components/pages/InternRegistrationForm";
import Blogs from "./components/pages/Blogs";
import BlogContent from "./components/sections/BlogContent";


declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
  const theme = createTheme(
    adaptV4Theme({
      typography: {
        fontFamily: "Nunito Sans",
      },
    })
  );
  let routes = (
    <Switch>
      <Route path="/" exact>
        <Navbar>
          <Home />
        </Navbar>
      </Route>
      <Route path="/about" exact>
        <Navbar>
          <About />
        </Navbar>
      </Route>
      <Route path="/careers" exact>
        <Navbar>
          <Careers />
        </Navbar>
      </Route>
      <Route path="/contact" exact>
        <Navbar>
          <ContactPage />
        </Navbar>
      </Route>
      <Route path="/faqs" exact>
        <Navbar>
          <FAQs />
        </Navbar>
      </Route>
      <Route path="/privacy-policy" exact>
        <Navbar>
          <Policy />
        </Navbar>
      </Route>
      <Route path="/gyan-at-aim4u" exact>
        <Navbar>
          <SkinzyTernLanding />
        </Navbar>
      </Route>
      <Route path="/intern-registration" exact>
        <Navbar>
          <RegistrationForm />
        </Navbar>
      </Route>
      <Route path="/blogs" exact>
        <Navbar>
          <Blogs />
        </Navbar>
      </Route>
      <Route path="/blogs/:slug" exact>
        <Navbar>
          <BlogContent />
        </Navbar>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>{routes}</Router>{" "}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
