import React from "react";
import { Spring, animated, SpringConfig } from "react-spring";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import quote from "../images/quote.svg";

const useStyles = makeStyles((theme) => ({
  SlideContainer: {
    position: "absolute",
    height: "50%",
    top: "50%",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transformOrigin: "50% 50%",
  },
  SlideCard: {
    position: "relative",
    padding: "5%",
    width: "70%",
    minWidth: "30%",
    background: "white",
    fontSize: "35px",
    transformOrigin: "50% 50%",
  },
  quote: {
    fontSize: "17px",
    fontWeight: 450,
    color: "#727375",
  },
  customer: {
    paddingTop: "10px",
    fontSize: "22px",
    fontWeight: 600,
  },
}));

interface Props {
  content: any;
  offsetRadius: number;
  index: number;
  animationConfig: SpringConfig;
  moveSlide: (direction: any) => void;
  delta?: any;
  down?: any;
  up?: any;
}

const Slide: React.FC<Props> = ({
  content,
  offsetRadius,
  index,
  animationConfig,
  moveSlide,
  delta,
  down,
  up,
}) => {
  const offsetFromMiddle = index - offsetRadius;
  const totalPresentables = 2 * offsetRadius + 1;
  const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));
  const classes = useStyles();

  const translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
  let translateY = -50;

  if (offsetRadius !== 0) {
    if (index === 0) {
      translateY = 0;
    } else if (index === totalPresentables - 1) {
      translateY = -100;
    }
  }

  if (offsetFromMiddle === 0 && down) {
    translateY += delta[1] / (offsetRadius + 1);
    if (translateY > -40) {
      moveSlide(-1);
    }
    if (translateY < -100) {
      moveSlide(1);
    }
  }
  if (offsetFromMiddle > 0) {
    translateY += translateYoffset;
  } else if (offsetFromMiddle < 0) {
    translateY -= translateYoffset;
  }

  return (
    <Spring
      to={{
        transform: `translateX(0%) translateY(${translateY}%) scale(${distanceFactor})`,
        top: `${
          offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 50) / offsetRadius
        }%`,
        opacity: distanceFactor * distanceFactor,
      }}
      config={animationConfig}
    >
      {(style) => (
        <animated.div
          className={classes.SlideContainer}
          style={{
            ...style,
            zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2),
          }}
        >
          <div
            className={classes.SlideCard}
            onClick={() => moveSlide(offsetFromMiddle)}
          >
            <Grid container>
              <Grid item xs={8}>
                <img src={quote} width="40px" height="40px" alt="quotes" />
                <div className={classes.quote}>{content.quote}</div>
                <div className={classes.customer}>{content.name}</div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/${content.image}`}
                    style={{ width: "100%" }}
                    alt="AiM4u Customer Review"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

export default Slide;
