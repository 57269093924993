import React, { useEffect } from "react";
import { Typography, Container, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../sections/Footer2";
import FadeIn from "../sections/FadeIn";
import AdsComponent from "../AdsComponent2";

import Pajamas from "../images/pajamas.jpg";
import Ai from "../images/AI.jpg";
import Challenges from "../images/Challenges.jpeg";
import NightOwl from "../images/NightOwl.jpeg";
import Mentor from "../images/Mentor.jpeg";
import Diversity from "../images/Diversity.jpg";

const useStyles = makeStyles((theme) => ({
  touch: {
    marginTop: "50px",
    color: "#FFF",
    fontWeight: 700,
    fontSize: "4em",
    textAlign: "center",
    marginBottom: "20px",
    fontFamily: "inherit",
  },
  root: {
    padding: "20px",
  },
  infoContainer: {
    textAlign: "center",
  },
  info: {
    display: "inline-block",
    padding: "40px 30px",
  },
   leftFeature: {
    textAlign: "center",
  },
  contentText: {
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    marginLeft: "0",
  },
  bullet: {
    backgroundColor: "#3F67F6",
    color: "#FFF",
    fontSize: "30px",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const About: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();
  return (
    <>
      <div>
        <FadeIn>
          <Typography className={classes.touch} style={{ color: "Black" }}>
            About Us
          </Typography>
        </FadeIn>
        <Container
          style={{
            textAlign: "left",
            color: "rgb(120 116 116 / 87%)",
            fontSize: "20px",
            fontWeight: 500,
            marginTop: "50px",
            marginBottom: "100px",
            // fontFamily: "Roboto",
          }}
        >
          <Container style={{ marginBottom: "5%" }}>
            <Grid className={classes.root} container>
              <Grid item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Ai}
                          /> */}
                          <h4 className={classes.bullet}> 01 </h4>
                        </Grid>
                        <Grid className={classes.contentText} item xs={12}>
                          Detect and get your skin issue resolved
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Mentor}
                          /> */}
                          <h4 className={classes.bullet}> 02 </h4>
                        </Grid>
                        <Grid className={classes.contentText} item xs={12}>
                          Oily and dry skin analyser
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.infoContainer} item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Challenges}
                          /> */}
                          <h4 className={classes.bullet}> 03 </h4>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Personalized Medicinal Plant-Based Skin Care
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Diversity}
                          /> */}
                          <h4 className={classes.bullet}> 04 </h4>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Personalize your daily skincare routine using technology
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.infoContainer} item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={NightOwl}
                          /> */}
                          <h4 className={classes.bullet}> 05 </h4>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Get personalized non-prescriptive skincare product recommendation
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <Avatar
                            sx={{ height: "110px", width: "110px" }}
                            // sx={{ bgcolor: deepOrange[500] }}
                            alt="Remy Sharp"
                            src={Pajamas}
                          /> */}
                          <h4 className={classes.bullet}> 06 </h4>
                        </Grid>

                        <Grid
                          className={classes.contentText}
                          item
                          xs={12}
                          marginLeft={"20%"}
                        >
                          Test your knowledge about Dermatology
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
      <AdsComponent dataAdSlot="4921144833" />
      <Footer />
    </>
  );
};

export default About;
