import React, { useState } from "react";
import axios from "axios";
import ModelContext from "./modelContext";

const OILY_URL =
  "https://ixxnih1oxd.execute-api.ap-south-1.amazonaws.com/oily-predict";
const DISEASE_URL =
  "https://fmjt635vse.execute-api.ap-south-1.amazonaws.com/get_27_proba";

const ModelState = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [prediction, setPrediction] = useState<
    | {
        oily: number;
        dry: number;
      }
    | undefined
  >(undefined);
  const [diseases, setDiseases] = useState<any>([]);

  const getPrediction = async (formData) => {
    setLoading(true);
    setError(undefined);
    await axios
      .post(OILY_URL, formData)
      .then(function (response) {
        console.log("resp", response);
        let data = response.data;
        let temp = {
          oily: Number(data.oily ?? "") * 100,
          dry: Number(data.dry ?? "") * 100,
        };
        setPrediction(temp);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setError("Something went wrong, please try again later");
        console.log(error);
      });
  };

  const getDiseases = async (formData) => {
    setLoading(true);
    setError(undefined);
    console.log("formData");
    console.log(formData);

    await axios
      .post(DISEASE_URL, formData)
      .then(function (response) {
        let data = response.data;
        let diseases = []; //let diseases = [];
        data.data.map((disease) => {
          //data.diseases.map((disease) => {
          let d = {
            title: disease.title,
            percentage: Number(disease.percentage),
          };
          diseases.push(d);
        });
        setDiseases(diseases);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setError("Something went wrong, please try again later");
        console.log(error);
      });
  };

  return (
    <ModelContext.Provider
      value={{
        loading,
        prediction,
        diseases,
        error,
        setPrediction,
        getPrediction,
        getDiseases,
        setDiseases,
        setLoading,
        setError,
      }}
    >
      {props.children}
    </ModelContext.Provider>
  );
};

export default ModelState;
