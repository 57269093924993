import React from 'react';
import {
    Container,
    Grid,
    Button,
    Box,
    CardActionArea,
    CardMedia,
    Typography,
    CardContent,
  } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import interns from '../images/interns.svg';
import drk from '../images/Dr_Dhananjay_Kalbande.png';
import Footer from '../sections/Footer2';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import PreviousInterns from "../../metadata/previousInterns.json";

const useStyles = makeStyles((theme?: any) => ({
    backgroundOne: {
        paddingTop: "3vh",
        background: "#3F67F6",
        paddingBottom: "3vh",
        height: '85vh',
        [theme.breakpoints.down("md")]: {
            minHeight: "90vh",
            width: "100%",
        },
    },
    backgroundTwo: {
        margin: '50px auto',
        height: '70vh',
        [theme.breakpoints.down("md")]: {
            minHeight: "100vh",
            width: "100%",
        },
    },
    backgroundThree: {
        minHeight: '80vh',
        [theme.breakpoints.down("md")]: {
            paddingTop: "70px",
            paddingBottom: "70px",
            minHeight: "90vh",
            width: "100%",
            boxSizing: "content-box",
        },
    },
    backgroundFour: {
        margin: '50px auto',
        minHeight: '80vh',
        [theme.breakpoints.down("md")]: {
            minHeight: "100vh",
            width: "100%",
        },
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    flex1: {
        display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column',
        [theme.breakpoints.down("md")]: {
            alignItems: 'center',
            textAlign:' center',
        },
    },
    flex2: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    },
    container1: {
        height: '100%',
        [theme.breakpoints.down("md")]: {
            height: '50%',
        },
    },
    container2: {
        marginLeft: '80px',
        [theme.breakpoints.down("md")]: {
            marginLeft: '0'
        },
    },
    container3: {
        marginLeft: '25px',
        [theme.breakpoints.up("md")]: {
            marginLeft: 'auto',
        },
    },
    heading1: {
        fontSize: '35px', 
        color: "#FFF", 
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
            fontSize: '35px',
            marginBottom: '25px'
        },
    },
    heading2: {
        fontSize: '25px', 
        width: "90%",
        color: "#EFEFEF",
        marginTop: "20px",
        [theme.breakpoints.down("md")]: {
            fontSize: '20px',
        },
    },
    vectorImg: {
        width: '500px',
        marginRight: "80px",
        [theme.breakpoints.down("md")]: {
            width: '280px',
            marginRight: "0",
        },
    },
    img1: {
        height: '400px',
        width: '440px',
        [theme.breakpoints.down("md")]: {
            width: '85%',
            height: '75%'
        },
    },
    button: {
        borderRadius: 40,
        width: "200px",
        textTransform: "none",
        fontSize: "18px",
        color: "#3F67F6",
    background: "#FFFFFF",
        margin: "30px auto",
        boxShadow: "none",
        border: "3px solid #FFFFFF",
        "&:hover": {
          background: "#0641b6",
          color: "#FFFFFF"
        },
    },
    heading3: {
        fontSize: '20px', 
        width: "90%",
        color: "#EFEFEF",
        [theme.breakpoints.down("md")]: {
            fontSize: '20px',
        },
    },
    heading4: {
        fontSize: '35px', 
        fontWeight: "700",
        marginBottom: '35px',
        [theme.breakpoints.down("md")]: {
            fontSize: '28px', 
            textAlign: 'center',
        },
    },
    heading5: {
        fontSize: '18px',
        listStyle: 'disc outside',
        [theme.breakpoints.down("md")]: {
            fontSize: '16px',
            textAlign: 'left',
        },
    },
    points: {
        marginBottom: "10px",
    },
    }));

const SkinzyTernLanding: React.FC = () => {
    const classes = useStyles();

    return (
        <div>
            <Grid className={classes.backgroundOne} container>
                <Grid item xs={12} sm={12} md={6} className={`${classes.flex1}  ${classes.container1}`}>
                    <Container className={`${classes.flex1} ${classes.container2}`}>
                        <h2 className={classes.heading1}> Gyan@AiM4u - Experience the power of learning with AiM4u </h2>
                        <p className={classes.heading2}> To get started with Artificial Intelligence and Machine Learning (AIML), register for our internship program today and embark on a transformative learning journey.  </p>
                        {/* <Link to='/intern-registration' style={{textDecoration: "none"}}> <Button variant="contained" color="secondary" className={classes.button}> Register Now </Button> </Link> */}
                        <a href='https://docs.google.com/forms/d/e/1FAIpQLScoeioH8Ng41AFbB8QGjbbCe8gORa6vxlNYqL1wPHTZ1vyVxg/viewform?pli=1' target="_blank" style={{textDecoration: "none"}}> <Button variant="contained" color="secondary" className={classes.button}> Register Now </Button> </a>
                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={`${classes.flex2} ${classes.container1}`}>
                    <img src={interns} alt="Interns" className={classes.vectorImg} />
                </Grid>
            </Grid>
            <Container className={classes.backgroundTwo}>
                <h4 style={{fontSize: "35px", textAlign: 'center', marginBottom: '0', fontWeight: "700"}}> Our Master Trainer </h4>
                <Grid container className={`${classes.flex1}`} style={{flexDirection: 'row', height: '100%',}}>
                    <Grid item xs={12} sm={12} md={6} className={`${classes.flex1} ${classes.container1}`}>
                        <img src={drk} alt="D.R.Kalbande" className={classes.img1} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={`${classes.flex1} ${classes.container1}`}>
                        <h2 style={{fontSize: "30px", padding: '0 0 15px',}}>  Dr. Dhananjay Kalbande </h2>
                        <p style={{fontSize: "18px", textAlign: 'justify'}}> Dr. Kalbande is a globally-recognized scholar,
teacher, consultant, and speaker in business innovation,
machine learning, and enterprise analytics. He has published
eight patents. He has written four books, as well as dozens of
influential articles in leading academic journals and
conferences.
                        </p>
                    </Grid>
                </Grid>
            </Container>
            <Grid container className={` ${classes.backgroundOne} ${classes.backgroundThree}`}>
                <Container className={`${classes.flex1} ${classes.container3}`} style={{height: '100%', color: "#FFF",}}>
                <h2 className={classes.heading4}> About our Internship Program </h2>
                <ul className={classes.heading5}>
                    <li className={classes.points}>
                    Ideal for students currently pursuing B.Tech, B.E, BSc IT, BCA or someone who aspire to work for dream tech companies such as Microsoft, Google and Barclays which sometimes do not come for campus placements.
                    </li>
                    <li className={classes.points}>
                        Limited seats available. Basis of your application, we will make a decision.
                    </li>
                    <li className={classes.points}>
                        The selected students will learn the Essentials of AI and ML in the span of 4 weeks.
                    </li>
                    <li className={classes.points}>
                        The selected students need to pay a fee of <strong>INR 2000 plus GST</strong>.
                    </li>
                    <li className={classes.points}>
                        Once the internship program is completed, we would help you with cover letter and resume, interview prep and corporate referencing. 
                    </li>
                    <li className={classes.points}>
                        The industry experts will take one mock interview and give feedback. An HR from a leading tech company will review your resume and LinkedIn account and share feedback.
                    </li>
                </ul>
                </Container>
            </Grid>
            <Container className={classes.backgroundFour}>
                <h4 style={{fontSize: "33px", textAlign: 'center', marginBottom: '35px', fontWeight: "700"}}> Some of our Previous Interns </h4>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {PreviousInterns.map((intern, index) => (
                        <Grid item xs={2} sm={4} md={3} key={index}>
                            <a href={intern.linkedin} target="_blank" style={{ textDecoration: 'none' }}>
                                <Card style={{ height: '100%' }}>
                                        <CardMedia component="img" height="250" image={`${process.env.PUBLIC_URL}/${intern.image}`} alt="green iguana" />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: "24px", textAlign: "center", color: '#000' }}>
                                                {intern.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: "16px", textAlign: "center" }}>
                                                {intern.status}
                                            </Typography>
                                        </CardContent>
                                </Card>
                            </a>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </div>
    )
}

export default SkinzyTernLanding;