import { isValidElement, useState } from "react";
import axios from "axios";

const RegistrationForm = () => {
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    collegeName: "",
    department: "",
    highestQualification: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setRegistrationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isValidElement = (data: Object) => {
    let isValid = true;
    for (let key in data) {
      if (data[key] === "") {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isValidElement(registrationData)) {
      alert("Please fill all the fields");
      return;
    }

    axios
      .post(
        "http://ec2-13-127-225-158.ap-south-1.compute.amazonaws.com/preregister",
        {
          data: registrationData,
        }
      )
      .then((res) => {
        alert("successefully registered.");
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);

        alert("error");
      });
  };

  return (
    <>
      <div className="bg-navbar w-full py-10">
        <div className="w-[90%] sm:w-[50%] mx-auto sm:mx-0 border-2 border-white rounded">
          <h1 className="text-4xl text-center text-white font-bold-200 pt-4 mb-3">
            Register For Internship
          </h1>

          <div className="p-3 w-100 rounded bg-blue">
            <form className="w-75 mx-auto sm:mx-0">
              <div className="mb-3">
                <div className="sm:flex w-100 gap-2 ">
                  <div className="flex-1">
                    <label className="form-label text-white text-1xl">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={registrationData.firstName}
                      onChange={handleChange}
                      name={"firstName"}
                    />
                  </div>
                  <div className="flex-1">
                    <label className="form-label text-white text-1xl">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={registrationData.lastName}
                      onChange={handleChange}
                      name={"lastName"}
                    />
                  </div>
                </div>
                <label className="form-label text-white text-1xl">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={registrationData.email}
                  onChange={handleChange}
                  name={"email"}
                />
                <label className="form-label text-white text-1xl">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={registrationData.contactNumber}
                  onChange={handleChange}
                  name={"contactNumber"}
                />
                <label className="form-label text-white text-1xl">
                  College Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={registrationData.collegeName}
                  onChange={handleChange}
                  name={"collegeName"}
                />
                <label className="form-label text-white text-1xl">
                  Department
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={registrationData.department}
                  onChange={handleChange}
                  name={"department"}
                />
                <label className="form-label text-white text-1xl">
                  Highest Qualification
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={registrationData.highestQualification}
                  onChange={handleChange}
                  name={"highestQualification"}
                />
                <button
                  onClick={handleSubmit}
                  className="py-2 border-2 border-white rounded w-100 mt-3 text-white text-2xl hover:bg-white hover:text-black"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
