import React from "react";
import BlogInfoSection from "../sections/BlogInfoSection";
import BlogItem from "../sections/BlogItem";
import {
    Container,
    Grid,
} from '@mui/material';
import Footer from "../sections/Footer2";
import { blogData } from '../../metadata/blogdata.js';

const Blogs: React.FC = () => {
    return (
        <>
            <BlogInfoSection />
            <Container sx={{ padding: '50px 20px' }}>
                <Grid container spacing={3}>
                    {
                        blogData.map(item => {
                            return(
                                <Grid key={item.key} item xs={12} sm={6} md={4}>
                                    <BlogItem data={item} />
                                </Grid>
                            )
                        })
                    }
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <BlogItem />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <BlogItem />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <BlogItem />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <BlogItem />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <BlogItem />
                    </Grid> */}
                </Grid>
            </Container>
            <Footer />
        </>
    )
};

export default Blogs;