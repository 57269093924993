import React from "react";
import { Container, Grid } from "@mui/material";

const People: React.FC = () => {
  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "50px", marginBottom: "100px", fontFamily: "Nunito Sans", }}
    >
      <Grid container>
        <Grid item md={6} sm={12}>
          <div style={{ color: "#3F67F6", fontWeight: 700, fontSize: "55px"}}>4 in 5</div>
          <div style={{ color: "#555555", fontWeight: 700, fontSize: "55px"}}>people in India develop skin diseases.</div>
          <div style={{ marginTop: "20px", width: "30%", height: "4px", backgroundColor: "#3F67F6" }} />
        </Grid>
        {/* <Grid item md={1}></Grid> */}
        <Grid item md={6} sm={12} sx={{padding: "0 15px"}}>
          <div style={{ fontSize: "20px", padding: "20px 0px", fontWeight: 400, color: "#5a5a5a" }}>
            At AiM4u, the health of our user is our priority. Research is the
            core of our organization.
          </div>
          <div style={{ fontSize: "20px", padding: "20px 0px", fontWeight: 600, color: "#5a5a5a" }}>Our algorithm detects skin diseases with 95% accuracy.</div>
          <div style={{ fontSize: "20px", padding: "20px 0px", fontWeight: 400, color: "#5a5a5a" }}>
            The preview of the Detection Algorithm can be used from the website in the features section.
          </div>
          <div style={{ fontSize: "20px", padding: "20px 0px", fontWeight: 400, color: "#5a5a5a" }}>
            90% of the skin patients do not visit a dermatologist. There are
            just 10,000 dermatologists for 1.3 billion people. Our app helps
            users connect with the dermatologist from anywhere, at anytime.
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default People;
