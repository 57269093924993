import React from "react";
import { Grid, Typography, Container, Button, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import map from "../images/map.jpg";
import Number from "../sections/Number";
//import Carousel from "../sections/Happy";
import FadeIn from "../sections/FadeIn";
// import LinkedIn from '@mui/icons-material/LinkedIn';
//import Contact from "../sections/Contact";
import Money from "../images/money.svg";
//import Smile from "../images/smile.svg";
import Footer from "../sections/Footer2";
import Happy from "../sections/Happy";
import Pajamas from "../images/pajamas.jpg";
import Ai from "../images/AI.jpg";
import Challenges from "../images/Challenges.jpeg";
import NightOwl from "../images/NightOwl.jpeg";
import Mentor from "../images/Mentor.jpeg";
import Diversity from "../images/Diversity.jpg";
import AdsComponent from "../AdsComponent";
const useStyles = makeStyles((theme) => ({
  map: {
    // backgroundImage: `url(${map})`,
    background: "#3F67F6",
    [theme.breakpoints.down("md")]: {
      padding: "50px 10px",
    },
  },
  root: {
    padding: "20px",
  },
  infoContainer: {
    textAlign: "center",
  },
  info: {
    display: "inline-block",
    padding: "40px 30px",
  },
  contentText: {
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    marginLeft: "0",
  },
  leftFeature: {
    textAlign: "center",
  },
  careers: {
    // flexGrow: 1,
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
  },

  head: {
    color: "#fff",
    fontSize: "2.6em",
    fontWeight: 500,
  },
  content: {
    color: "#fff",
    fontSize: "1.4em",
    padding: "25px",
  },
  bottom: {
    background: "#1E1E30",
    borderRadius: 20,
  },
  button: {
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: 600,
    border: "2px solid #fff",
    color: "#000",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    background: "#5ce1e6",
    "&:hover": {
      backgroundColor: "#0a067a",
      boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
      color: "#fff",
      transform: "translateY(-3px)",
    },
  },
  submit: {
    fontSize: "7 em",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: 1000,
    color: "#000",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    background: "#5ce1e6",
    margin: "25 px",
    "&:hover": {
      backgroundColor: "#0a067a",
      boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
      color: "#fff",
      transform: "translateY(-3px)",
    },
  },
  card: {
    padding: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "30px",
    paddingBottom: "30px",
    fontSize: "20px",
    fontWeight: 600,
    margin: "10px",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: "25px",
  },
  touch1: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "2em",
    textAlign: "center",
    margin: "20px auto 10px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
  },
  touch2: {
    color: "#FFF",
    fontWeight: 800,
    fontSize: "3em",
    textAlign: "center",
    margin: "10px auto 20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  },
  touch3: {
    color: "#FFF",
    fontWeight: 500,
    fontSize: "1.5em",
    textAlign: "center",
    margin: "15px auto 15px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem",
    },
  },
  work: {
    color: "#000",
    fontWeight: 800,
    fontSize: "2.5em",
    textAlign: "center",
    marginTop: "75px",
    marginBottom: "20px",
  },

  project: {
    color: "#FFF",
    fontWeight: 5000,
    fontSize: "2em",
    textAlign: "left",
    marginBottom: "10px",
    marginLeft: "22px",
  },
  textField: {
    padding: "10px 20px",
  },
}));

const Careers: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div>
        <div className={classes.map}>
          <Container>
            <Grid className={classes.careers} spacing={4} container>
              <Typography className={classes.touch1}>
                Work with us at AiM4u
              </Typography>
              <Typography className={classes.touch2}>
                Careers
              </Typography>
              <Typography className={classes.touch3}>
                Have a zeal to contribute to the real world and create a visible impact in the lives of people? We are looking for passionate people to join us on mission. 
              </Typography>
            </Grid>
          </Container>
        </div>
        <Number />
        <div
          className={classes.center}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            paddingLeft: "80 %",
            paddingRight: "80 %",
          }}
        >
          <FadeIn>
            <Typography className={classes.work}>
              WHY WORK WITH US ?{" "}
            </Typography>
          </FadeIn>

          <Container style={{ marginBottom: "5%" }}>
            <Grid className={classes.root} container>
              <Grid item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="Money" /> */}
                          <Avatar
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Ai}
                          />
                        </Grid>
                        <Grid className={classes.contentText} item xs={12}>
                          Work on cutting edge technologies AI, ML and Flutter
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="send" /> */}
                          <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Mentor}
                          />
                        </Grid>
                        <Grid className={classes.contentText} item xs={12}>
                          Develop your skills with skilled mentors
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.infoContainer} item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="review" /> */}
                          <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Challenges}
                          />
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Find newer comfort zones through challenges
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="peace" /> */}
                          <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={Diversity}
                          />
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Queer Friendly. Diversity is appreciated.
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.infoContainer} item md={4} sm={12}>
                <Grid container className={classes.infoContainer}>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="review" /> */}
                          <Avatar
                            // sx={{ bgcolor: deepOrange[500] }}
                            sx={{ height: "100px", width: "100px" }}
                            alt="Remy Sharp"
                            src={NightOwl}
                          />
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Flexible Hours and vacations. Night Owls Welcome
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                  <Grid item xs={12} className={classes.info}>
                    <FadeIn>
                      <Grid className={classes.leftFeature} container>
                        <Grid item xs={2} marginLeft={"30%"}>
                          {/* <img width="45" height="45" src={Money} alt="peace" /> */}
                          <Avatar
                            sx={{ height: "110px", width: "110px" }}
                            // sx={{ bgcolor: deepOrange[500] }}
                            alt="Remy Sharp"
                            src={Pajamas}
                          />
                        </Grid>

                        <Grid
                          className={classes.contentText}
                          item
                          xs={12}
                          marginLeft={"20%"}
                        >
                          Work in pajamas
                        </Grid>
                      </Grid>
                    </FadeIn>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <AdsComponent dataAdSlot="3771714697" />
        {/* <Happy classes={classes} /> */}
        <div
          className={classes.center}
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            paddingLeft: "40 %",
            paddingRight: "40 %",
          }}
        >
          <Typography
            style={{
              padding: "20px 0px",
              textAlign: "center",
              fontSize: "40px",
              fontWeight: 500,
            }}
          >
            Interested in working with us?
          </Typography>
          <Typography
            style={{
              padding: "30px 0 20px",  
              textAlign: "center",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            Please drop in an email with your resume and use subject :
            Opp_Name_Role for quicker response.
          </Typography>

          <Button
            variant="contained"
            style={{
              background: "#3F67F6",
              margin: "25px",
              padding: "5px 50px",
              textAlign: "center",
              color: "#fff",
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
            className={classes.submit}
            href="mailto:drkalbande@aim4u.co.in"
          >
            Email Us!
          </Button>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Careers;
