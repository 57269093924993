import React from "react";
import {
  Box,
  // Container,
  Button,
  // Card,
  // CardHeader,
  // CardContent,
  Typography,
  // CardActions,
} from "@mui/material";
import FadeIn from "./FadeIn";
import { ReactComponent as Consult } from "../images/consult.svg";
import { ReactComponent as Quiz } from "../images/quiz.svg";
import { ReactComponent as Search } from "../images/search.svg";
import { ReactComponent as Distype } from "../images/type.svg";
// import { PredictionModal } from "./PredictionModal";
import { DiseaseModal } from "./DiseaseModal";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import Intern from '../images/Internship.png'
import Dry from '../images/Dry result1.jpg'
import Oily from '../images/Oily skin having acne (1).jpg'
export const useStyles = makeStyles((theme) => ({
  container: {
    background: "#cfd9fd",
    // background:"blue",
    padding: "60px 40px",
  },
  block_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "10px",
  },
  button: {
    width: "200px",
    
    borderRadius: "30px",
    background: "#3F67F6",
    fontSize: "20px",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0641b6",
      color: "white",
    },
  },
  block_head: {
    paddingTop: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
  },
  block_text: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 400,
    // height: "180px",
  },
  block_icon: {
    height: "150px",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    height: "445px",
    width: "236px",
    margin: "15px 0 25px",
  },
  featurePreview: {
    textAlign: "center",
    color: "#555555",
    fontSize: "17px",
    padding: "20px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height:300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const FeatPrev = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [disOpen, setDisOpen] = React.useState(false);
  const dishandleOpen = () => setDisOpen(true);
  const dishandleClose = () => setDisOpen(false);

  
  const classes = useStyles();

  const Consultbox = () => {
    return (
      <div className={classes.block}>
        <Consult className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Get Your Skin Issue Resolved
        </Typography>
        {/* <Typography variant="body" gutterBottom className={classes.block_text}>
          Get an online consultation about your skin from our selection of
          certified doctors.
        </Typography> */}
         <Typography variant="body" gutterBottom className={classes.block_text}>
          Download the DermaLens App 
        </Typography>
        <Button className={classes.button} href="#download">
          Try Now
        </Button>
      </div>
    );
  };

  const Quizbox = () => {
    return (
      <div className={classes.block}>
        <Quiz className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Test Your Knowledge
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
          {/* Are you a dermatology student? Test your identification skills with
          our technology. */}
          Are you a Student of BioTech/Medical/Science/General?
        </Typography>
        
        <Button
          className={classes.button}
          href="https://quiz.aim4u.co.in/"
          target="_blank"
        >
          Test your Skills
        </Button>
      </div>
    );
  };

  const Typebox = () => {
    return (
      <div className={classes.block}>
        <Distype className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Know your skin type
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
     
          Oily and Dry Skin Prediction
        </Typography>

        <Button className={classes.button} onClick={handleOpen}>
          Our Model Results
        </Button>
        {/* <PredictionModal
          open={open}
          handleClose={handleClose}
          detect={"skin"}
        /> */}


        {/* For Temporary Purpose */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            Our AI Model validation accuracy of 98% for oily and dry skin classification
            </Typography>
            <img src={Dry} alt="Dry skin type" style={{ width: '50%', marginBottom: '10px' }} />
            <img src={Oily} alt="Oily skin type" style={{ width: '50%', marginBottom: '10px' }} />

          </Box>
        </Modal>
      </div>
    );
  };

  const Searchbox = () => {
    const classes = useStyles();
    return (
      <div className={classes.block}>
        <Search className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Detect Your Disease
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
          Not sure about your skin infection? Identify your disease by clicking
          a picture.
        </Typography>
        <Button className={classes.button} onClick={dishandleOpen}>
          Try Now
        </Button>
        <DiseaseModal
          open={disOpen}
          handleClose={dishandleClose}
          detect={"skin"}
        />
        <Button onClick={handleOpen}>Open modal</Button>
      </div>
    );
  };


  const Gyan = () => {
    return (
      <div className={classes.block}>
        {/* <Quiz className={classes.block_icon} /> */}
        <img
        src={Intern}
        alt="Intern working on a project"
        className={classes.block_icon}
        />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Apply for Internship
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
        Elevate your skills to the next level
          
        </Typography>
       
        <Button className={classes.button} href="https://gyan.aim4u.co.in/"
          target="_blank">
          Visit Gyan
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container} id="features">
      <div className={classes.featurePreview}>
        <FadeIn>
          <h1>FEATURE PREVIEW</h1>
        </FadeIn>
      </div>
      <FadeIn>
        <div className={classes.block_container}>
          <Typebox />
          <Quizbox />
          {/* <Searchbox /> */}
          <Consultbox />
          <Gyan />
        </div>
      </FadeIn>
    </div>
  );
};

export default FeatPrev;
