import React from "react";
import img1 from "../images/newSplashScreen.svg";
import img2_new from "../images/img2_new.svg";
import img3 from "../images/phones.svg";
import "../styles/Phone.scss";

const Phone: React.FC = () => {
  return (
    <div className="device-collection">
      <div className="phone2-container">
        <div className="device2 phone2">
          <img src={img1} alt="Skinsy Dermalens App" />
        </div>
      </div>
      <div className="phone-container">
        <div className="device phone">
          <img src={img2_new} alt="Skinnzi App Image Skinnsi" />
        </div>
      </div>
    </div>
  );
};

export default Phone;
