export const menu: { text: string; link: string }[] = [
  { text: "Home", link: "/" },
  // { text: "About", link: "/about" },
  // { text: "Careers", link: "/careers" },
  // { text: "Blogs", link: "/blogs" },
  // { text: "Gyan@AiM4u", link: "/gyan-at-aim4u/" },
  { text: "About", link: "/about" },
  { text: "FAQs", link: "/faqs" },
  { text: "Contact", link: "/contact" },
  // { text: "Privacy-Policy", link: "/privacy-policy" },
];

export const menu2: { text: string; link: string }[] = [
  // { text: "Home", link: "/" },
  { text: "About", link: "/about" },
  { text: "Careers", link: "/careers" },
  // { text: "Blogs", link: "/blogs" },
  { text: "Contact", link: "/contact" },
  { text: "FAQs", link: "/faqs" },
];
