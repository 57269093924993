import React from "react";
import "typeface-asap";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ModelState from "./context/ModelContext/ModelState";

ReactDOM.render(
    <ModelState>
        <App />
    </ModelState>
    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
