import React from "react";
import VerticalCarousel from "./VerticalCarousel";
import { config, SpringConfig } from "react-spring";

interface Props {
  testimonials: { [key: string]: any }[];
}

const Slider: React.FC<Props> = ({ testimonials }) => {
  const sliderState: {
    goToSlide: number;
    offsetRadius: number;
    showNavigation: boolean;
    config: SpringConfig;
  } = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "90%",
          margin: "0 auto",
        }}
      >
        <VerticalCarousel
          slides={testimonials}
          offsetRadius={sliderState.offsetRadius}
          showNavigation={sliderState.showNavigation}
          animationConfig={sliderState.config}
        />
      </div>
    </div>
  );
};

export default Slider;
