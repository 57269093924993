import React from "react";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Slider from "./Slider";
import FadeIn from "./FadeIn";
import Testimonials from "../../metadata/testimonials.json";

const useStyles = makeStyles((theme) => ({
  testimonial: {
    paddingTop: "5vh",
    background: "#B0C4DE",
  },
  testimonialContent: {
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "100px",
    },
  },
  heading: {
    fontSize: "56px",
    fontWeight: 600,
    wordSpacing: "10px",
  },
  content: {
    marginTop: "50px",
    background: "#ffffff",
    border: "10px solid #f2f2f2",
    padding: "20px 20px",
    fontSize: "17px",
    fontWeight: 500,
  },
}));

const Testimonial: React.FC = () => {
  const classes = useStyles();
  return (
    <FadeIn>
      {Testimonials.length > 0 && (
        <Grid className={classes.testimonial} container>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.testimonialContent}>
              <div className={classes.heading}>
                Our customers love what we do...
              </div>
              <div className={classes.content}>
                Our users appreciate our simple UI and quick consultation
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Slider testimonials={Testimonials} />
          </Grid>
        </Grid>
      )}
    </FadeIn>
  );
};

export default Testimonial;
