import React from "react";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Frame from "./Frame";
import FadeIn from "./FadeIn";
import { Container, Typography } from "@mui/material";
import snap from "../images/snap.svg";
//import send from "../images/send.svg";
import review from "../images/review.svg";
//import peace from "../images/peace.svg";
import stet from "../images/stethoscope.svg";
import ai from "../images/ai.svg";
import control from "../images/control.svg";
import nintyfive from "../images/nintyfive.svg";
//import book from "../images/Book.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
  gettingStarted: {
    textAlign: "center",
    color: "#555555",
    fontSize: "17px",
  },
  infoContainer: {
    textAlign: "center",
  },
  info: {
    display: "inline-block",
    padding: "20px 0px",
  },
  leftFeature: {
    textAlign: "right",
  },
  rightFeature: {
    textAlign: "left",
  },
  contentText: {
    fontSize: "18px",
    fontWeight: 500,
  },
  icons: {
    marginTop: "10px",
  },
  contentHead: { fontWeight: 550, fontSize: "30px" },
}));

const Start: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.gettingStarted}>
        <FadeIn>
          <h1>GETTING STARTED</h1>
        </FadeIn>
      </div>
      <Container style={{ marginBottom: "5%" }}>
        <Grid className={classes.root} container>
          <Grid item md={4} sm={12}>
            <Grid container className={classes.infoContainer}>
              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} container>
                    <Grid item xs={2}>
                      <img width="35" height="35" src={snap} alt="snap" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.contentHead}
                        variant="h4"
                        gutterBottom
                      >
                        Click
                      </Typography>
                    </Grid>

                    <Grid className={classes.contentText} item xs={12}>
                      Take the photo of your skin problem.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>
              {/* <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.leftFeature} container>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.contentHead}
                        variant="h4"
                        gutterBottom
                      >
                        Upload
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <img width="35" height="35" src={send} alt="send" />
                    </Grid>
                    <Grid className={classes.contentText} item xs={12}>
                      Crop the affected area and send it for the analysis.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid> */}
              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} container>
                    <Grid item xs={2}>
                      <img width="35" height="35" src={review} alt="send" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.contentHead}
                        variant="h4"
                        gutterBottom
                      >
                        Detect
                      </Typography>
                    </Grid>

                    <Grid className={classes.contentText} item xs={12}>
                      Our AI assistant will provide three most prominent
                      diseases.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>

              {/* <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.leftFeature} container>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.contentHead}
                        variant="h4"
                        gutterBottom
                      >
                        Book
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <img width="35" height="35" src={book} alt="book" />
                    </Grid>
                    <Grid className={classes.contentText} item xs={12}>
                    Get your skin issue resolved with our dermatologists
                          at your convenience.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid> */}

              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} container>
                    <Grid item xs={2}>
                      <img width="35" height="35" src={stet} alt="consult" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        className={classes.contentHead}
                        variant="h4"
                        gutterBottom
                      >
                        Consult
                      </Typography>
                    </Grid>

                    <Grid className={classes.contentText} item xs={12}>
                      Talk to the dermatologist over a Video Call. Only on
                      receiving a prescription by the dermatologists, the money
                      will get deducted from the wallet.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Frame />
          </Grid>
          {/* <Grid item md={4} sm={12}>
            <FadeIn>
              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  fontSize: "40px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Why Dermalens?
              </div> */}
          <Grid item md={4} sm={12}>
            <Grid container className={classes.infoContainer}>
              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <div
                    style={{
                      fontSize: "35px",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    Why Dermalens?
                  </div>
                </FadeIn>
              </Grid>
              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} spacing={6} container>
                    <Grid item xs={2}>
                      <img
                        width="55"
                        height="55"
                        src={ai}
                        className={classes.icons}
                        alt="send"
                      />
                    </Grid>
                    <Grid className={classes.contentText} item xs={10}>
                      DermaLens Uses AI to analyze an uploaded photo of your
                      skin and detect a wide array of skin diseases
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>

              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} spacing={6} container>
                    <Grid item xs={2}>
                      <img
                        width="55"
                        height="55"
                        src={nintyfive}
                        className={classes.icons}
                        alt="send"
                      />
                    </Grid>
                    <Grid className={classes.contentText} item xs={10}>
                      DermaLens can predict skin conditions with 95% accuracy.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>

              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} spacing={6} container>
                    <Grid item xs={2}>
                      <img
                        width="55"
                        height="55"
                        src={control}
                        className={classes.icons}
                        alt="send"
                      />
                    </Grid>
                    <Grid className={classes.contentText} item xs={10}>
                      DermaLens helps you take control of your skin.
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>
            </Grid>
          </Grid>
          {/* <p className={classes.contentText}>Take control of your skin.</p>
              <p className={classes.contentText}>
                On finding any concern, just click and upload.
              </p>
              <p className={classes.contentText}>
                Our technology analyzes your photo and gives you instant result,
                reducing your anxiety.
              </p>
              <p className={classes.contentText}>
                Detect skin issues when it is most treatable and does not spread
                much.
              </p> */}
          {/* </FadeIn>
          </Grid> */}

          {/* <Grid className={classes.infoContainer} item xs={12}>
            <Grid
              style={{ paddingTop: "20px" }}
              container
              className={classes.infoContainer}
            >
              <Grid item xs={12} className={classes.info}>
                <FadeIn>
                  <Grid className={classes.rightFeature} container spacing={4}>
                    <Grid item md={4} sm={12}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img
                            width="35"
                            height="35"
                            src={review}
                            alt="review"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.contentHead}
                            variant="h4"
                            gutterBottom
                          >
                            Book
                          </Typography>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Get your skin issue resolved with our dermatologists
                          at your convenience.
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item md={4} sm={12}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img width="35" height="35" src={peace} alt="peace" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.contentHead}
                            variant="h4"
                            gutterBottom
                          >
                            Pay
                          </Typography>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Make your payment to confirm your slot. The money will
                          be safe in our wallet.
                        </Grid>
                      </Grid>
                    </Grid> 
                    <Grid item md={4} sm={12}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img width="35" height="35" src={peace} alt="peace" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.contentHead}
                            variant="h4"
                            gutterBottom
                          >
                            Consult
                          </Typography>
                        </Grid>

                        <Grid className={classes.contentText} item xs={12}>
                          Talk to the dermatologist over a Video Call. Only on
                          receiving a prescription by the dermatologists, the
                          money will get deducted from the wallet.
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FadeIn>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default Start;
