import React from "react";
import Download from "../sections/Download";
import Start from "../sections/Start";
import Testimonial from "../sections/Testimonial";
import Footer from "../sections/Footer2";
import People from "../sections/People";
import FeatPrev from "../sections/feat";
import AdsComponent from "../AdsComponent";
import AdsComponent3 from "../AdsComponent3";

const Home: React.FC = () => {
  return (
    <>
      <Download />
      <AdsComponent dataAdSlot="7006599739" />
      <People />
      <AdsComponent dataAdSlot="9914984046" />
      <Start />
      {/* <Stats /> */}
      {/* <ShowBoardOfScience /> */}
      <FeatPrev />
      <Testimonial />
      <AdsComponent3 dataAdSlot="4525783079" />
      {/* <ShowDoctor /> */}
      <Download bottom />
      {/* <AdsComponent dataAdSlot="2228065715" /> */}
      <Footer />
    </>
  );
};

export default Home;
