import React from "react";
import { Typography, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Footer from "../sections/Footer2";
import FadeIn from "../sections/FadeIn";

const useStyles = makeStyles((theme) => ({
  touch: {
    marginTop: "50px",
    color: "#FFF",
    fontWeight: 700,
    fontSize: "4em",
    textAlign: "center",
    marginBottom: "20px",
    fontFamily: "ui-serif",
  },
}));

const About: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <FadeIn>
          <Typography className={classes.touch} style={{ color: "Black" }}>
            Privacy Policy
          </Typography>
        </FadeIn>
        <Container
          style={{
            textAlign: "left",
            color: "#000000",
            fontSize: "20px",
            fontWeight: 400,
            marginTop: "50px",
            marginBottom: "100px",
            // fontFamily: "Roboto",
          }}
        >
          <div>
            We, <strong>M/s.AiM4u Software Solutions Pvt.Ltd</strong>
            (hereinafter referred to as the “<strong>Company</strong>”) having
            its registered office at 8th Floor, SP-TBI, Munshi Nagar,Andheri (W), Mumbai, Maharashtra 400058
            represented by its members, where such expression shall, unless
            repugnant to the context thereof, be deemed to include its
            respective legal heirs, representatives, administrators, permitted
            successors and assigns. The creator of this Privacy Policy ensures
            steady commitment to your privacy with regard to the protection of
            your invaluable information. This privacy policy contains
            information about an online website called https://www.aim4u.co.in and
            mobile application called ‘DermaLens’ (collectively called the“
            <strong>Platform</strong>”). In order to provide You with Our
            uninterrupted use of services, we may collect and, in some
            circumstances, disclose information about you with your permission.
            To ensure better protection of Your privacy, We provide this notice
            explaining Our information collection and disclosure policies, and
            the choices You make about the way Your information is collected and
            used.
          </div>
          <div>&nbsp;</div>
          <div>
            This Privacy Policy shall be in compliance with the General Data
            Protection Regulation (GDPR) in effect from June 20, 2023 and any and
            all provisions that may read to the contrary shall be deemed to be
            void and unenforceable as of that date. If you do not agree with the
            terms and conditions of our Privacy Policy, including in relation to
            the manner of collection or use of your information, please do not
            use or access the Site. If you have any questions or concerns
            regarding this Privacy Policy, you should contact our Customer
            Support Desk at support@aim4u.co.in
          </div>
          <div>&nbsp;</div>
          <div>
            ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING
            ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED
            HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS
            OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATERS OF
            THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
            CONTAINED WITHIN IT IN ANY MANNER.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol>
            <strong>
              <li>DEFINITIONS:</li>
            </strong>
          </ol>
          <ol type="a">
            <li>
              “We”, “Our”, and “Us” shall mean and refer to the creators of this
              privacy policy.
            </li>
            <li>
              “You”,“Your”, “Yourself” and “User” shall mean and refer to
              natural and legal individuals who use the Website.
            </li>
            <li>
              “Personal Information” shall mean and refer to any personally
              identifiable information that We may collect from You. For removal
              of any doubts, please refer to Clause 2.
            </li>
            <li>
              “Third Parties” refer to any website, company or individual apart
              from the User and the creator of this Website.
            </li>
          </ol>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={2}>
            <strong>
              <li>INFORMATION WE COLLECT</li>
            </strong>
          </ol>
          <div>
            We are committed to respecting Your online privacy. We further
            recognize Your need for appropriate protection and management of any
            Personal Information You share with us. We may collect the following
            information:
          </div>
          <ol type="a">
            <li>
              Personal data such as, but not limited to name, age, gender,
              location, email-id, etc.
            </li>
            <li> The images of your harmed/infected skin.</li>
            <li>
              Tracking Information such as, but not limited to the IP address of
              your device and Device ID when connected to the Internet. [This
              information may include the URL that you just came from (whether
              this URL is on the Website or not), which URL you next go to
              (whether this URL is on the Website or not), your computer browser
              information, and other information associated with your
              interaction with the Site.]
            </li>
            <li>
              Payment Gateways of third party including details of billing,
              Debit and Credit Card details
            </li>
            <li> Details of Website/App usage for analytics.</li>
          </ol>
          <div>
            This privacy policy also applies to data we collect from users who
            are not registered as members of this site, including, but not
            limited to, browsing behaviour, pages viewed etc.We also collect and
            store personal information provided by you from time to time on the
            Site. We only collect and use such information from you that we
            consider necessary for achieving a seamless, efficient and safe
            experience, customized to your needs including:
          </div>
          <ol type="a">
            <li>To enable the provision of services opted for by you;</li>
            <li>
              To communicate necessary account and service related information
              from time to time;
            </li>
            <li>To allow you to receive quality customer care services;</li>
            <li>To comply with applicable laws, rules and regulations</li>
          </ol>
          <div>&nbsp;</div>
          <div>
            Where any service requested by you involves a third party, such
            information as is reasonably necessary by the Company to carry out
            your service request may be shared with such third party.
          </div>
          <div>
            We also do use your contact information to send you offers based on
            your interests and prior activity. The Company may also use contact
            information internally to direct its efforts for service
            improvement, but shall immediately delete all such information upon
            withdrawal of your consent for the same through our ‘
            <strong>unsubscribe</strong>’ button or through an email to be sent
            to support@aim4u.co.in
          </div>
          <div>&nbsp;</div>
          <div>
            We collect and store your search details on the Website, including
            your search history, the usage of the website and the features and
            time you have used the website for. <br /> Further, you may from
            time to time choose to provide payment related financial information
            (credit card, debit card, bank account details, billing address
            etc.) on the Site. We are committed to keeping all such sensitive
            data/information safe at all times and ensure that such
            data/information is only transacted over secure Site of approved
            payment gateways which are digitally encrypted, and provide the
            highest possible degree of care available under the technology
            presently in use. We shall collect and store all such information in
            our internal Company servers for a finite period time, as long as
            you are a User of the Website, and shall immediately delete all such
            information upon expiry of your Usership. We shall also delete all
            aforementioned communication upon withdrawal of Usership of any
            single Party to the communication.
          </div>
          <div>&nbsp;</div>
          <div>
            The Company will not use your financial information for any purpose
            other than to complete a transaction with you. To the extent
            possible, we provide you the option of not divulging any specific
            information that you wish for us not to collect, store or use. You
            may also choose not to use a particular service or feature on the
            Site, and opt out of any non-essential communications from the
            Website.
          </div>
          <div>&nbsp;</div>
          <div>
            Further, transacting over the internet has inherent risks which can
            only be avoided by you following security practices yourself, such
            as not revealing account/login related information to any other
            person and informing our customer care team about any suspicious
            activity or where your account has/may has been compromised.
          </div>
          <div>&nbsp;</div>
          <div>
            At every stage prior to, during or after information collection, you
            have the right to access all personally identifiable information
            provided, rectify or alter all personally identifiable information
            provided, restrict the level of information to be retained as per
            your sole discretion and object to the retention, use and potential
            disclosure of the personally identifiable information.
          </div>
          <div>&nbsp;</div>
          <ol start={3}>
            <strong>
              <li>OUR USE OF YOUR INFORMATION</li>
            </strong>
          </ol>
          <div>The information provided by You shall be used to</div>
          <ol type="a">
            <li>Improve and develop new products, services, and features;</li>
            <li>
              The App receives the image of your harmed skin which is shared by
              you shall be used to detect the skin disease if any , and alerts
              you to consult a doctor. The Mobile Application detects up to ten
              types of skin diseases you may suffer from and the results of the
              same can be shared with the doctors for quick and accurate
              diagnosis of skin diseases. The results are completely based on
              the image you shared which will be analyzed and the results
              displayed to that effect. The photograph shared by you will be
              stored in a secured database which will be used a reference
              framework for future research. This information will be retained
              by us and it will not be shared to any third parties for any
              purpose.
            </li>
          </ol>
          <div>
            For more details about the nature of such communications, please
            refer to our Terms of Service. Further, Your personal data and
            Sensitive Personal data may be collected and stored by Us for
            internal record.
          </div>
          <div>
            We use Your tracking information such as IP addresses, and or Device
            ID to help identify you and to gather broad demographic information.
            We will not sell, license or trade Your personal information. We
            will not share your personal information with others unless they are
            acting under our instructions or we are required to do so by law.
          </div>
          <div>
            Information collected via Our server logs includes users' IP
            addresses and the pages visited; this will be used to manage the web
            system and troubleshoot problems. We also use third-party analytics,
            tracking, optimization and targeting tools to understand how users
            engage with our website so that we can improve it and cater
            personalized content/ads according to their preferences.
          </div>
          <div>&nbsp;</div>
          <ol start={4}>
            <strong>
              <li>HOW INFORMATION IS COLLECTED</li>
            </strong>
          </ol>
          <div>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected. If
            the same is not identified to you, you have the right to request the
            Company to elucidate the purpose of collection of said personal
            information, pending fulfilment of which you shall not be mandated
            to disclose any information whatsoever.
          </div>
          <div>&nbsp;</div>
          <div>
            We will collect and use your personal information solely with the
            objective of fulfilling those purposes specified by us, within the
            scope of consent of the individual concerned or as required by law.
            We will only retain personal information as long as necessary for
            the fulfilment of those purposes. We will collect personal
            information by lawful and fair means and with the knowledge and
            consent of the individual concerned.
          </div>
          <div>&nbsp;</div>
          <div>
            Personal data should be relevant to the purposes for which it is to
            be used, and, to the extent necessary for those purposes, should be
            accurate, complete, and up-to-date.
          </div>
          <div>&nbsp;</div>
          <ol start={5}>
            <strong>
              <li>COOKIES</li>
            </strong>
          </ol>
          <div>
            We use data collection devices such as “cookies” on certain pages of
            our Websites. “Cookies” are small files sited on your hard drive
            that assist us in providing customized services. We also offer
            certain features that are only available through the use of a
            “cookie”. Cookies can also help us provide information, which is
            targeted to your interests. Cookies may be used to identify logged
            in or registered users. Our Website uses session cookies to ensure
            that you have a good experience. These cookies contain a unique
            number, your 'session ID', which allows our server to recognise your
            computer and 'remember' what you've done on the site. The benefits
            of this are:
          </div>
          <ol type="a">
            <li>
              You only need to log in once if you're navigating secure areas of
              the site
            </li>
            <li>
              Our server can distinguish between your computer and other users,
              so you can see the information that you have requested.
            </li>
          </ol>
          <div>
            You can choose to accept or decline cookies by modifying your
            browser settings if you prefer. This may prevent you from taking
            full advantage of the Website. We also use various third-party
            cookies for usage, behavioral, analytics and preferences data. The
            following are the different types of Cookies used on the Website:
            <br />{" "}
          </div>
          <ol type="a">
            <li>
              <strong>Authentication cookies</strong>:To identify the user and
              share the content that he or she requested for.
            </li>
            <li>
              <strong>Functionality cookies</strong>: For customized user
              experience and resuming past course progress.
            </li>
            <li>
              <strong>Tracking, optimization and targeting cookies</strong>:To
              capture usage metric on device, operating system, browser etc. To
              capture behavioral metrics for better content delivery. To cater
              and suggest most suited products and services.
              <br />{" "}
            </li>
          </ol>
          <div>&nbsp;</div>
          <ol start={6}>
            <strong>
              <li>EXTERNAL LINKS ON THE WEBSITE</li>
            </strong>
          </ol>
          <div>
            The Website may include advertisements, hyperlinks to other
            websites, applications, content or resources. We have no control
            over any websites or resources, which are provided by companies or
            persons other than Us. You acknowledge and agree that We are not
            responsible for the availability of any such external sites or
            resources, and do not endorse any advertising, services/products or
            other materials on or available from such websites or resources. You
            acknowledge and agree that We are not liable for any loss or damage
            which may be incurred by you as a result of the availability of
            those external sites or resources, or as a result of any reliance
            placed by you on the completeness, accuracy or existence of any
            advertising, products or other materials on, or available from, such
            websites or resources. These external websites and resource
            providers may have their own privacy policies governing the
            collection, storage, retention and disclosure of Your Personal
            Information that You may be subject to. We recommend that You enter
            the external Website and review their privacy policy. If you require
            a list of third-party links on our Website, please write to us at
            support@aim4u.co.in and we will provide you with the same.
          </div>
          <div>&nbsp;</div>
          <ol start={7}>
            <strong>
              <li>YOUR RIGHTS</li>
            </strong>
          </ol>
          <div>
            Unless subject to an exemption, you have the following rights with
            respect to your personal data:
          </div>
          <ol type="a">
            <li>
              The right to request a copy of your personal data which we hold
              about you;
            </li>
            <li>
              The right to request for any correction to any personal data if it
              is found to be inaccurate or out of date;
            </li>
            <li>
              The right to withdraw Your consent to the processing at any time;
            </li>
            <li>The right to object to the processing of personal data;</li>
            <li>The right to lodge a complaint with a supervisory authority</li>
            <li>
              The right to obtain information as to whether personal data are
              transferred to a third country or to an international
              organization.
            </li>
          </ol>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={8}>
            <strong>
              <li>CONFIDENTIALITY</li>
            </strong>
          </ol>
          <div>
            You further acknowledge that the Website may contain information
            which is designated confidential by Us and that You shall not
            disclose such information without our prior written consent. Your
            information is regarded as confidential and therefore will not be
            divulged to any third party, unless if legally required to do so to
            the appropriate authorities. We will not sell, share, or rent your
            personal information to any third party or use your e-mail address
            for unsolicited mail. Any emails sent by us will only be in
            connection with the provision of agreed services, and you retain
            sole discretion to seek for discontinuation of such communications
            at any point of time.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={9}>
            <strong>
              <li>OTHER INFORMATION COLLECTORS</li>
            </strong>
          </ol>
          <div>
            Except as otherwise expressly included in this Privacy Policy, this
            document only addresses the use and disclosure of information we
            collect from you. To the extent that you disclose your information
            to other parties, whether they are on our Websites or on other sites
            throughout the Internet, different rules may apply to their use or
            disclosure of the information you disclose to them. To the extent
            that we use third party advertisers, they adhere to their own
            privacy policies. Since we do not control the privacy policies of
            the third parties, you are subject to ask questions before you
            disclose your personal information to others.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={10}>
            <strong>
              {" "}
              <li>OUR DISCLOSURE OF YOUR INFORMATION</li>
            </strong>
          </ol>
          <div>
            Due to the existing regulatory environment, we cannot ensure that
            all of your private communications and other personally identifiable
            information will never be disclosed in ways not otherwise described
            in this Privacy Policy. By way of example (without limiting and
            foregoing), we may be forced to disclose information to the
            government, law enforcement agencies or third parties. Therefore,
            although we use industry standard practices to protect your privacy,
            we do not promise, and you should not expect, that your personally
            identifiable information or private communications would always
            remain private. We do, however assure you that any and all
            disclosure of your personally identifiable information shall be
            personally intimated to you through an email sent to your provided
            email address.
          </div>
          <div>
            As a matter of policy, we do not sell or rent any personally
            identifiable information about you to any third party. However, the
            following describes some of the ways that your personally
            identifiable information may be disclosed:
          </div>
          <ol type="a">
            <li>
              External Service Providers: There may be a number of services
              offered by external service providers that help you use our
              Websites. If you choose to use these optional services, and in the
              course of doing so, disclose information to the external service
              providers, and/or grant them permission to collect information
              about you, then their use of your information is governed by their
              privacy policy.
            </li>
            <li>
              Law and Order: We cooperate with law enforcement inquiries, as
              well as other third parties to enforce laws, such as: intellectual
              property rights, fraud and other rights. We can (and you authorize
              us to) disclose any information about you to law enforcement and
              other government officials as we, in our sole discretion, believe
              necessary or appropriate, in connection with an investigation of
              fraud, intellectual property infringements, or other activity that
              is illegal or may expose us or you to legal liability.
            </li>
          </ol>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={11}>
            <strong>
              <li>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</li>
            </strong>
          </ol>
          <div>
            Following registration, You can review and change the information
            You submitted at the stage of registration, except Email ID. An
            option for facilitating such change shall be present on the Website
            and such change shall be facilitated by the User. If You change any
            information, We may or may not keep track of Your old information.
            We will not retain in our files information you have requested to
            remove for certain circumstances, such as to resolve disputes,
            troubleshoot problems and enforce our terms and conditions. Such
            prior information shall be completely removed from our databases,
            including stored ‘back up’ systems. If you believe that any
            information we are holding on you is incorrect or incomplete, or to
            remove Your profile so that others cannot view it, the User needs to
            remediate, and promptly correct any such incorrect information.
          </div>
          <div>&nbsp;</div>
          <ol start={12}>
            <strong>
              <li>CONTROL OF YOUR PASSWORD</li>
            </strong>
          </ol>
          <div>
            When you sign up to become a Member, you will also be asked to
            choose a password. You are entirely responsible for maintaining the
            confidentiality of your password. It is important that you protect
            it against unauthorized access of your account and information by
            choosing your password carefully and keeping your password and
            computer secure by signing out after using our services.
          </div>
          <div>
            You agree not to use the account, username, email address or
            password of another Member at any time or to disclose your password
            to any third party. You are responsible for all actions taken with
            your login information and password, including fees. If you lose
            control of your password, you may lose substantial control over your
            personally identifiable information and may be subject to legally
            binding actions taken on your behalf. Therefore, if your password
            has been compromised for any reason, you should immediately change
            your password. You agree to notify us immediately if you suspect any
            consistent unauthorized use of your account or access to your
            password even after changing it
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={13}>
            <strong>
              <li>SECURITY</li>
            </strong>
          </ol>
          <div>
            We treat data as an asset that must be protected against loss and
            unauthorized access. We employ many different security techniques to
            protect such data from unauthorized access by members inside and
            outside the Company. We follow generally accepted industry standards
            to protect the Personal Information submitted to Us and information
            that we have accessed.
          </div>
          <div>&nbsp;</div>
          <ol start={14}>
            <strong>
              <li>SEVERABILITY </li>
            </strong>
          </ol>
          <div>
            Each paragraph of this privacy policy shall be and remain separate
            from and independent of and severable from all and any other
            paragraphs herein except where otherwise expressly indicated or
            indicated by the context of the agreement. The decision or
            declaration that one or more of the paragraphs are null and void
            shall have no effect on the remaining paragraphs of this privacy
            policy.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={15}>
            <strong>
              <li>AMENDMENT</li>
            </strong>
          </ol>
          <div>
            Our Privacy Policy may change from time to time. The most current
            version of the policy will govern our use of your information and
            will always be at the Website.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={16}>
            <strong>
              <li>AUTOMATED DECISION MAKING</li>
            </strong>
          </ol>
          <div>
            As a responsible company, we do not use automatic decision-making or
            profiling.
          </div>
          <div>
            <strong>&nbsp;</strong>
          </div>
          <ol start={17}>
            <strong>
              <li>
                CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS
              </li>
            </strong>
          </ol>
          <div>
            To withdraw your consent, or to request the download or delete your
            data with us for any or all our products &amp; services at any time,
            please email to support@aim4u.co.in from your registered email
            address.
          </div>
          <div>&nbsp;</div>
          <ol start={18}>
            <strong>
              <li>CONTACT US</li>
            </strong>
          </ol>
          <div>
            If you have any questions or concerns regarding this privacy policy,
            you should contact us by sending an e-mail to support@aim4u.co.in
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default About;
