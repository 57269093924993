import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import "../styles/Frame.css";
import img1 from "../images/img1.jpeg";

const useStyles = makeStyles((theme) => ({
  px__screen__frame: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: "hidden",
    borderRadius: "inherit",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: `url(${img1})`,
  },
}));

const Frame: React.FC = () => {
  const classes = useStyles();
  return (
    <div className="temp-wrapper">
      <div className="px">
        <div className="px__body">
          <div className="px__body__cut" />
          <div className="px__body__speaker" />
          <div className="px__body__sensor" />

          <div className="px__body__mute" />
          <div className="px__body__up" />
          <div className="px__body__down" />
          <div className="px__body__right" />
        </div>
        <div className="px__screen">
          <div className="px__screen__">
            <div className={classes.px__screen__frame} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frame;
