import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// import FadeIn from "./FadeIn";
import { menu } from "./utils";
import {
  useScrollTrigger,
  Button,
  Fab,
  IconButton,
  Zoom,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Drawer,
  List,
  // Divider,
  ListItem,
} from "@mui/material";
import logo from "../images/aim4u-logo.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  noScrollbar: {
    background: "#3F67F6",
    boxShadow: "none",
  },
  onScrollbar: {
    background: "#3F67F6",
  },
  logoTypo: {
    marginLeft: "10px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  hamburgerIcon: {
    outline: "none !important",
  },
  nav: {
    margin: "0px 3px",
    padding: "5px 15px",
    textTransform: "none",
    color: "#ffffff",
    fontSize: "17px",
    fontFamily: "Nunito Sans",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#031095"
    },
    "&:focus": {
      outline: "none",
    },
  },
  tryButton: {
    width: "180px",
    height: "45px",
    fontSize: "14px",
    textTransform: "uppercase",
    letterSpacing: "2px",
    fontWeight: 600,
    color: "#000",
    border: "none",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    background: "#5ce1e6",
    "&:hover": {
      backgroundColor: "#0a067a",
      boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
      color: "#fff",
      transform: "translateY(-3px)",
    },
  },
  hamburger: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  fab: {
    backgroundColor: "#0641b6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0641b6",
      color: "#FFF",
    },
    "&:focus": {
      outline: "none",
    }
  },
  menu: {
    height: "100vh",
    backgroundColor: "#3F67F6",
  },
  menuDivider: {
    backgroundColor: "#8982ff",
  },
  menuItem: {
    padding: "24px",
    fontFamily: "Nunito Sans",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 500,
    outline: "none",
    textAlign: "center",
  },
  iconContainer: {
    color: "#ffffff",
    textAlign: "right",
    padding: "10px 20px",
  },
}));

interface ScrollTopProps {
  setIsScroll: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  window?: Window;
}

const ScrollTop: React.FC<ScrollTopProps> = (props) => {
  const { setIsScroll, children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  useEffect(() => {
    setIsScroll(trigger);
  }, [trigger, setIsScroll]);

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

interface MenuListProps {
  onClose: () => void;
}

const MenuList: React.FC<MenuListProps> = ({ onClose }) => {
  let history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.menu} role="presentation">
      <div className={classes.iconContainer}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={onClose}
          size="large">
          <CloseIcon color="inherit" />
        </IconButton>
      </div>
      <List>
        {menu.map((m, i) => (
          <div key={i}>
            {/* <Divider className={classes.menuDivider} /> */}

            <ListItem
              className={classes.menuItem}
              button
              onClick={() => {
                onClose();
                history.push(m.link);
              }}
            >
              <span style={{ width: "100%", }}>
                {m.text}
              </span>
            </ListItem>
          </div>
        ))}
      </List>
      {/* <div style={{ padding: "24px" }}>
        <FadeIn>
          <Button
            href="https://play.google.com/store/apps/details?id=com.skinzy.dermalens"
            className={classes.tryButton}
          >
            DermaLens
          </Button>
        </FadeIn>
      </div> */}
    </div>
  );
};

interface NavbarProps {
  children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const toggleDrawer = () => setOpen((open) => !open);
  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer anchor={"top"} open={open} onClose={toggleDrawer}>
        <MenuList onClose={toggleDrawer} />
      </Drawer>
      <AppBar className={isScroll ? classes.onScrollbar : classes.noScrollbar}>
        <Toolbar>
          <img width="30" height="30" src={logo} alt="logo" />
          <Typography className={classes.logoTypo} variant="h6">
            AiM4u
          </Typography>
          <div className={classes.sectionDesktop}>
            {menu.map((m, i) => (
              <Button
                key={i}
                className={classes.nav}
                onClick={() => history.push(m.link)}
              >
                {m.text}
              </Button>
            ))}
            
            <Button href="https://gyan.aim4u.co.in/" className={classes.tryButton}>Interns@Gyan</Button>
          </div>
          <div className={classes.hamburger}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              size="large"
              className={classes.hamburgerIcon}>
              <MenuIcon />
            </IconButton>
            <Button href="https://gyan.aim4u.co.in/" className={classes.tryButton}>Interns@Gyan</Button>
          </div>
          
        </Toolbar>
        
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <div>{props.children}</div>
      <ScrollTop setIsScroll={setIsScroll} {...props}>
        <Fab
          className={classes.fab}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};

export default Navbar;
