import React from "react";
import CountUp from "react-countup";
import { Grid } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import countMap from "../images/countmap.jpg";
//import StarIcon from "@mui/icons-material/Star";
import EventIcon from "@mui/icons-material/Event";
import "../styles/Stats.css";

const Number: React.FC = () => {
  return (
    <div style={{ backgroundImage: `url(${countMap})`}} className="statistic-section">
      <Grid container>
        <Grid item sm={6} xs={12}>
          <div className="counter">
            <div>
              <PeopleIcon className="stats-icon" />
            </div>
            <h2 className="timer count-title count-number">
              <CountUp start={0} end={21} delay={1} duration={5} />
            </h2>
            <div className="stats-line-black"></div>
            <p className="stats-text">Employees</p>
          </div>
        </Grid>

        


        <Grid item sm={6} xs={12}>
          <div className="counter">
            <div>
              <EventIcon className="stats-icon" />
            </div>
            <h2 className="timer count-title count-number">
              <CountUp start={0} end={2} delay={1} duration={5} />
            </h2>
            <div className="stats-line-black"></div>
            <p className="stats-text">Years In Market</p>
          </div>
        </Grid>
        {/* <Grid item md={4} xs={12}>
          <div className="counter">
            <div className="stats-icon">
              <StarIcon style={{ fontSize: "50px" }} />
            </div>
            <h2 className="timer count-title count-number">
              <CountUp start={0} end={5} delay={1} duration={1000} />
            </h2>
            <div className="stats-line-black"></div>
            <p className="stats-text">Average Employee Rating</p>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Number;
