import React from "react";
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Typography
    
} from '@mui/material';
import { Link } from "react-router-dom";

const BlogItem: React.FC<any> = ({data}) => {

    return (
      <Link to={`/blogs/${data.url}`} style={{textDecoration: 'none'}}>
            <Card sx={{ maxWidth: 345, height: '100%' }}>
      {/* Dummy Card Data For Testing Purpose */}
      {/* <CardMedia
        component="img"
        alt="Blog Cover Image"
        height="140"
        image="https://macln.files.wordpress.com/2011/01/blog_logo.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent> */}

      <CardMedia
        component="img"
        alt="Blog Cover Image"
        height="140"
        image={`${process.env.PUBLIC_URL}/${data.coverImage}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.subtitle}
        </Typography>
      </CardContent>
    </Card>
    </Link>
    )
};

export default BlogItem;