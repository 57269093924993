import React from "react";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../sections/Footer2";

const data = [
  {
    title: "Is there any cost to detect skin issues on DermaLens app?",
    content: "No. We believe our research should be available for everyone. ",
  },
  {
    title: "Do I have to pay to consult dermatologists?    ",
    content:
      "Yes. The dermatologists charge for the consultation. You can choose a dermatologist based on your availability.",
  },
  {
    title: "Are these doctors credible?",
    content:
      "Yes. We do thorough research about their degrees before onboarding them.",
  },
  {
    title: "Does more percentage means more probability? ",
    content:
      "Yes. Usually, when your skin issues match with the images in our database, you get high percentage for a particular disease.In this case you should get your skin issues checked by a healthcare professional.",
  },
  {
    title: "	What happens if the doctor does not turn up for the video call?",
    content:
      "We do not deduct the money from your wallet. Only once the dermatologists give you their diagnosis, we pay to the dermatologist from your wallet.",
  },
  {
    title: "	How accurate are the results?",
    content:
      "Our results are highly accurate. Our clinical trials have been approved by leading hospitals of Mumbai and the research is backed by renowned dermatologists across India. There might be cases in which a prominent disease gets a lower percentage. That is mainly because of cropping issue or lighting issue. Feel free to share images of skin issues and dermatologist report or biopsy report at support@aim4u.co.in. ",
  },
  {
    title: "Does Dermalens work with dermatologists?",
    content: "Yes. We work with a team of experienced dermatologists.",
  },
  {
    title: "	When should you use Dermalens to detect skin issues?",
    content:
      "If you have a slightest doubt or see abnormal skin patterns of the body, get the area checked. ",
  },
  {
    title: "	Do dermatologists check the images you upload?",
    content:
      "No. Only when you consult a dermatologist and allow them to see your images, they will be able to see.",
  },
  {
    title: "	Will dermatologist ask for the skin images?",
    content:
      "Yes. At times, because of issues with video call bandwidth, the doctor may ask you to send the image of affected area.",
  },
];

const useStyles = makeStyles((theme?: any) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const FAQs: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Container>
          <div
            style={{
              textAlign: "center",
              fontSize: "32px",
              fontWeight: 500,
              padding: "30px 0px",
            }}
          >
            Frequently Asked Questions
          </div>
          <div style={{ width: "100%", marginBottom: "70px" }}>
            {data.map((d, i) => (
              <Accordion style={{ width: "100%" }} key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ background: "rgb(211 234 248)" }}
                >
                  <Typography className={classes.heading}>{d.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.content}>{d.content}</div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Container>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="autorelaxed"
          data-ad-client="ca-pub-6169839016655974"
          data-ad-slot="8066182923"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        <Footer />
      </div>
    </>
  );
};

export default FAQs;
