import React from "react";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer2";

const ContactPage: React.FC = () => {
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
};

export default ContactPage;
