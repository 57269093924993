import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    
} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router-dom";
import { blogData } from '../../metadata/blogdata.js';

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "60px 10px",
      [theme.breakpoints.down("md")]: {
        padding: "40px 30px"
      },
    },
    blogTitle: {
        color: "#000",
        fontSize: "35px",
        fontWeight: "600",
        margin: "15px auto",
        [theme.breakpoints.down("md")]: {
            fontSize: "25px",
          },
      },
      metaInfo: {
        color: "#000",
        fontSize: "17px",
        fontWeight: "500",
        [theme.breakpoints.down("md")]: {
            fontSize: "15px",
        },
      },
      coverImg: {
        width: "90%",
        height: "500px",
        margin: "30px 0",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          height: "auto",
      },
      },
      content: {
        width: "80%",
        fontSize: "20px",
        textAlign: "justify",
        [theme.breakpoints.down("md")]: {
          width: "90%",
          fontSize: "16px",
      },
      },
}));

const BlogContent: React.FC<any> = () => {
    const classes = useStyles();
    const location = useLocation();
    const [data, setData] = useState({
      "key": "",
      "url": "",
      "title": "",
      "labels": [],
      "date": "",
      "readTime": "",
      "coverImage": "",
      "content": ""
    });

    useEffect(() => {
        const url = location.pathname.split('/')[2];
        const res = blogData.filter(obj => {
          return obj.url === url
        })
        if(res.length > 0) {
          setData(res[0]);
        }
    }, [])
    

    return (
      <>
      { data.key &&
        <Container className={classes.root}>
            {/* Demo Content For Testing Purpose */}
            {/* <header>
                <h2 className={classes.blogTitle}> From Identity to Inspiration: A Reading List on Why We Run </h2>
                <p className={classes.metaInfo}> <span> Published on March 13, 2023 </span> &bull; <span> 3 min read </span> </p>
            </header>
            <img className={classes.coverImg} src="https://macln.files.wordpress.com/2011/01/blog_logo.jpg" alt="" />
            <div className={classes.content}>
            Running is a sport of contradiction. Finishing a marathon is at once extraordinary and unremarkable: Running 26.2 miles is an exceptional achievement, but it’s also one that 1.1 million people complete every year.
<br /><br />
In running, themes of life and death coexist. On one hand, it’s a celebration of what the human body can do and achieve. Some events, like cancer charity runs, are associated with the will to survive. But at the other end, in the sport’s most extreme races like the 135-mile Badwater Ultramarathon in California’s Death Valley, participants teeter on the edge of mortality. The truth is, the marathon was born out of, quite literally, death.*
<br /><br />
* The first marathoner, an Athenian man delivering news of a Greek victory after a battle, collapsed and died after finishing his journey.
<br /><br />
Other contrasts abound. Sociological analyses of running culture also show how it can be egalitarian and unequal at once: Theoretically, running has no barrier to entry, and all you really need is a good pair of sneakers, but the socioeconomic and racial disparities in the world of competitive running are hard to ignore. The median household income of the Runner’s World print audience in 2022 was $120,050 (well above the 2021 national median of $70,784), implying that running is somehow associated with wealth. (A study on the meaning of running in American society looks at how running perpetuates ideals of capitalism and consumerism.) On the other hand, the simple act of jogging by yourself, in your own neighborhood, can be deadly for those less privileged; the most high-profile running stories in recent years haven’t been about heroes, but victims.
<br /><br />
All of which is to say, running can be a complex subject, and essays and features about running fascinate me, especially after I became a runner myself.
            </div> */}
            <header>
                <h2 className={classes.blogTitle}> {data.title} </h2>
                <p className={classes.metaInfo}> <span> Published on {data.date} </span> &bull; <span> {data.readTime} min read </span> </p>
            </header>
            <img className={classes.coverImg} src={`${process.env.PUBLIC_URL}/${data.coverImage}`} alt="" />
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: data.content }}>
              
            </div>
        </Container>
      }
      </>
    )
};

export default BlogContent;